@import url("https://fonts.googleapis.com/css?family=Montserrat:600&display=swap");

:root {
  --main-bg-color: #2d2727;
  --card-bg-color: #413543;
}

.screenbg {
  background-color: var(--main-bg-color);
}
.cardbg {
  background-color: var(--card-bg-color);
}

.content {
  display: flex;
  border: 2px solid #eae2e1;
  border-radius: 5px;
  cursor: pointer;
}

.content.heart-active {
  border-color: #f9b9c4;
  background: #fbd0d8;
}

.heart {
  background: url("img.png") no-repeat;
  background-position: left;
  background-size: 2900%;
}

.text {
  font-size: 21px;
  margin-left: 30px;
  color: grey;
  font-family: "Montserrat", sans-serif;
}

.numb:before {
  content: "12";
  font-size: 21px;
  margin-left: 7px;
  font-weight: 600;
  color: #9c9496;
  font-family: sans-serif;
}

.numb.heart-active:before {
  content: "13";
  color: #000;
}

.text.heart-active {
  color: #000;
}

.heart.heart-active {
  animation: animate 0.8s steps(28) 1;
  background-position: right;
}

@keyframes animate {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}
